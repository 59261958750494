.gb-content-element {
  display: flex;
  flex-direction: column;
  margin-bottom: calc-pixel(50);

  > img {
    width: 100%;
    height: 100%;
    margin-right: 0;
    margin-bottom: calc-pixel(16);
  }
  @include screen-md() {
    flex-direction: row;

    > img {
      max-width: calc-pixel(669);
      height: 100%;
      margin-right: calc-pixel(30);
      margin-bottom: 0;
      flex-basis: 60%;
    }

    &__text {
      flex-basis: 40%;
    }
  }
}
