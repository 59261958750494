.gb-box {
  background-color: transparent;
  border: solid 1px $gb_white;
  width: calc-pixel(224 - 60);
  height: calc-pixel(300 - 60);
  color: $gb-white;
  text-align: center;
  padding: calc-pixel(30);
  margin: 0 calc-pixel(12);
  @include screen-md {
    width: calc-pixel(240 - 60);
  }
  @include tweakpoint-small {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    margin: calc-pixel(8) 0;
  }
}
