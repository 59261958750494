.info-box {
  &__container {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  &__pivot {
    position: absolute;

    &--hidden {
      visibility: hidden;
    }
  }

  &__content {
    transform: translate(100%, 50%);
    pointer-events: all;
    position: absolute;
    padding: calc-pixel(16);
    background-color: $gb_black;
    color: $gb_white;
    width: calc-pixel(350);
    min-height: calc-pixel(150);
    max-height: calc-pixel(425);
    right: 0;
    bottom: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;

    &__headline {
      flex: 0 0 auto;

      h4 {
        font-size: calc-pixel(16);
      }
    }

    &__scrollview {
      flex: 1 1 auto;
      position: relative;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: calc-pixel(5);
      }

      &::-webkit-scrollbar-track-piece {
        background-color: $gb_grey_700;
        border-color: $gb_black;
        border-width: 2px 1px;
        border-style: solid;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 7px;
        background-color: $gb_white;
        border-color: $gb_white;
        border-style: solid;
        border-width: 1px 3px;
      }
    }

    &__bottom {
      flex: 0 0 auto;
      margin-top: calc-pixel(24);
    }

    button {
      cursor: pointer;
      margin-bottom: calc-pixel(8);
    }

    .info-box__image-container {
      position: relative;
      width: 100%;
      margin-bottom: calc-pixel(16);
      border: none;
      padding: 0;

      img {
        max-height: calc-pixel(250);
        object-fit: scale-down;
        object-position: center;
      }

      &:active,
      &:focus {
        outline: none;
      }

      &__main {
        display: block;
        width: 100%;
      }

      .info-box__play-button {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: calc-pixel(128);
      }
    }

    p {
      color: $gb_white;
      margin-bottom: 0;
      margin-right: calc-pixel(16);

      a,
      a:visited {
        color: $gb_white;

        &::before {
          @extend .web20-icon, ::before;
          @extend .web20-icon-arrow-link-light-right, ::before;
          padding: 0 5px 0 0;
        }

        &:active,
        &:focus,
        &:hover {
          &::before {
            text-decoration: none;
          }
        }
      }
    }

    &--hidden {
      display: none;
    }
  }

  &__arrow {
    width: 0;
    height: 0;
    transform-origin: 0 50%;
    position: absolute;
    transform: rotate(45deg);
    -webkit-transform: rotate(360deg);
    border-style: solid;
    border-width: calc-pixel(8) calc-pixel(32) calc-pixel(8) 0;
    border-color: transparent #000000 transparent transparent;
  }
}
