// Links
a,
a:visited {
  color: $gb_brandPrimary;
  text-decoration: none;
  transition: color $gb_transitionNormal;

  &::before {
    text-decoration: underline;
  }

  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;

    &::before {
      text-decoration: none;
    }
  }

  /*
}
 .link-button {
  @extend .button;

  &::before {
    content: none;
  }

  &:hover {
    text-decoration: none;
  }

  i::before {
    font-size: 1.1875rem;
    position: absolute;
    left: 1.5625rem;
  } */

  /* &--transparent {
    background: transparent;

    &--white {
      border-color: $gb_white;
      color: $gb_white !important;

      i {
        color: $gb_white;
      }
    }

    &--black {
      border-color: $gb_black;
      color: $gb_black;

      i {
        color: $gb_black;
      }
    }
  }

  &--white {
    background: $gb_white;
    border-color: $gb_white;

    &--brand-primary {
      color: $gb_brand-primary !important;

      i {
        color: $gb_brand-primary;
      }
    }
  } */
}

/*
p,
ol,
ul {
  a,
  a:visited {
    &::before {
      @extend .gb-icon::before;
      @extend .gb-icon-arrow-right::before;
      padding: 0 5px 0 0;
    }

    &:active,
    &:focus,
    &:hover {
      &::before {
        text-decoration: none;
      }
    }
  }
} */
