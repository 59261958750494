/**
 * @license
 * MyFonts Webfont Build ID 3073689, 2015-08-11T08:47:33-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: AktivGrotesk-Bold by Dalton Maag
 * URL: http://www.myfonts.com/fonts/daltonmaag/aktiv-grotesk/bold/
 *
 * Webfont: AktivGrotesk-Light by Dalton Maag
 * URL: http://www.myfonts.com/fonts/daltonmaag/aktiv-grotesk/light/
 *
 * Webfont: AktivGrotesk-Medium by Dalton Maag
 * URL: http://www.myfonts.com/fonts/daltonmaag/aktiv-grotesk/medium/
 *
 * Webfont: AktivGrotesk-Regular by Dalton Maag
 * URL: http://www.myfonts.com/fonts/daltonmaag/aktiv-grotesk/regular/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3073689
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2013 Dalton Maag Ltd. All rights reserved. This font may not be altered in any way without prior permission of Dalton Maag Ltd.
 *
 * © 2015 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
// @import url('//hello.myfonts.net/count/2ee699');

@font-face {
  font-family: 'AktivGrotesk';
  src: url('#{$gb_webfontPath}AktivGroteskGeberit_W_Bd.eot');
  src: url('#{$gb_webfontPath}AktivGroteskGeberit_W_Bd.eot?#iefix') format('embedded-opentype'), url('#{$gb_webfontPath}AktivGroteskGeberit_W_Bd.woff2') format('woff2'), url('#{$gb_webfontPath}AktivGroteskGeberit_W_Bd.woff') format('woff'), url('#{$gb_webfontPath}AktivGroteskGeberit_W_Bd.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('#{$gb_webfontPath}AktivGroteskGeberit_W_Lt.eot');
  src: url('#{$gb_webfontPath}AktivGroteskGeberit_W_Lt.eot?#iefix') format('embedded-opentype'), url('#{$gb_webfontPath}AktivGroteskGeberit_W_Lt.woff2') format('woff2'), url('#{$gb_webfontPath}AktivGroteskGeberit_W_Lt.woff') format('woff'), url('#{$gb_webfontPath}AktivGroteskGeberit_W_Lt.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('#{$gb_webfontPath}AktivGroteskGeberit_W_Md.eot');
  src: url('#{$gb_webfontPath}AktivGroteskGeberit_W_Md.eot?#iefix') format('embedded-opentype'), url('#{$gb_webfontPath}AktivGroteskGeberit_W_Md.woff2') format('woff2'), url('#{$gb_webfontPath}AktivGroteskGeberit_W_Md.woff') format('woff'), url('#{$gb_webfontPath}AktivGroteskGeberit_W_Md.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('#{$gb_webfontPath}AktivGroteskGeberit_W_Rg.eot');
  src: url('#{$gb_webfontPath}AktivGroteskGeberit_W_Rg.eot?#iefix') format('embedded-opentype'), url('#{$gb_webfontPath}AktivGroteskGeberit_W_Rg.woff2') format('woff2'), url('#{$gb_webfontPath}AktivGroteskGeberit_W_Rg.woff') format('woff'), url('#{$gb_webfontPath}AktivGroteskGeberit_W_Rg.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
