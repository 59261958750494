a {
  &.button {
    color: $gb-white;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

.button {
  position: relative;
  font-size: .8125rem;
  line-height: 1.3;
  font-weight: $font-weight-bold;
  color: $gb-white;
  text-transform: uppercase;
  letter-spacing: .04375rem;
  border: 1px solid $gb_brandPrimaryMedium;
  background-color: $gb_brandPrimaryMedium;
  padding: calc-pixel(10) calc-pixel(30);
  cursor: pointer;
  transition: padding .25s ease-out;

  i {
    margin-right: 10px;

    &::before {
      bottom: 1px;
      font-size: .8125rem;
      position: relative;
    }
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:active {
    border: 1px solid $gb_brandPrimaryDark;
    background-color: $gb_brandPrimaryDark;
    padding-left: calc-pixel(35);
    padding-right: calc-pixel(25);
  }

  &:hover {
    border: 1px solid $gb_brandPrimary;
    background-color: $gb_brandPrimary;
    padding-left: calc-pixel(35);
    padding-right: calc-pixel(25);
  }

  &--white {
    color: $gb_black;
    border-color: $gb_black;
    background-color: $gb_white;
    text-transform: none;

    &:hover {
      border: 1px solid $gb_black;
      color: $gb_white;
      background-color: $gb_black;
    }

    &:active {
      border: 1px solid $gb_black;
      background-color: $gb_black;
    }
  }

  &--transparent {
    background: transparent;
    border-color: $gb_white;
    color: $gb_white;
    text-transform: none;

    &:hover {
      background: $gb_white;
      color: $gb_black;
      border-color: $gb_black;
      padding: calc-pixel(10) calc-pixel(30);
    }
  }
}

.close-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: calc-pixel(28);
  color: $gb_brandPrimaryMedium;

  &:active,
  &:focus {
    outline: none;
  }
}

.gb-chevrons {
  pointer-events: none;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &__left,
  &__right {
    height: 100%;
    display: flex;
    align-items: center;

    button {
      pointer-events: all;
      cursor: pointer;
      display: flex;
      margin: calc-pixel(10);
      padding: 0;
      font-size: calc-pixel(60);
      border: none;
      background-color: transparent;
      color: white;
      opacity: .4;
      transition: opacity .15s ease-out, transform .15s ease-out;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:active,
      &:focus {
        outline: none;
      }

      &:hover {
        transform: translateX(0);
        opacity: 1;
      }
    }

    &__left {
      button {
        transform: translateX(5px);
      }
    }

    &__right {
      button {
        transform: translateX(-5px);
      }
    }
  }
}

.button-open-grid {
  background: url('/src/assets/images/minimap/open-grid.svg');
  border: 0;
  cursor: pointer;
  position: absolute;
  height: calc-pixel(16);
  right: calc-pixel(12);
  top: calc-pixel(12);
  width: calc-pixel(16);
}

.button-open-map {
  background: url('/src/assets/images/minimap/map-icon.svg');
}

.button-open-gallery {
  background: url('/src/assets/images/minimap/open-grid.svg');
}

.button-open-gallery,
.button-open-map {
  border: 0;
  cursor: pointer;
  width: calc-pixel(44);
  height: calc-pixel(44);
  background-color: rgba(0, 0, 0, .75);
  background-repeat: no-repeat;
  background-position: center;
  background-size: calc-pixel(18);
}
