// Colors
$gb_brandPrimary: #376eb4;
$gb_brandPrimaryMedium: #0a5a96;
$gb_brandPrimaryDark: #004673;

$gb_greyBlue: #84909d;
$gb_grey_025: #fafafa;
$gb_grey_050: #f1f1f1;
$gb_grey_075: #ececec;
$gb_grey_100: #e4e4e4;
$gb_grey_400: #999999;
$gb_grey_650: #666666;
$gb_grey_700: #707070;

$gb_white: #ffffff;
$gb_black: #000000;

// Widths and spaces
$gb_sizeUnit: 'rem' !default; // px || rem

$gb_breakpointXL: calc-pixel(1400) !default;
$gb_breakpointLG: calc-pixel(1200) !default;
$gb_breakpointMD: calc-pixel(992) !default;
$gb_breakpointSM: calc-pixel(768) !default;
$gb_breakpointXS: calc-pixel(480) !default;

$gb_containerMinWidth: calc-pixel(320) !default;
$gb_containerMaxWidth: calc-pixel(1230) !default;
$gb_headerHeightSm: calc-pixel(60) !default;
$gb_headerHeightMd: calc-pixel(80) !default;
$gb_mainNavigationWidth: calc-pixel(240) !default;

// Fonts
$gb_fontFamilyBase: 'AktivGrotesk', helvetica, arial, sans-serif !default;
$gb_webfontPath: '../fonts/grotesk/' !default;
$gb_lineHeightBase: 1.5 !default;
$gb_fontSizeBase: calc-pixel(15) !default;
$gb_iconPath: '../fonts/icons/' !default;
$gb_iconFont: 'geberitBaseIconFont';

$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;

// Images
$gb_imagePath: '../images/' !default;

// Shadow
$gb_boxShadowDefault: 0 .5rem .5rem .0625rem rgba(0, 0, 0, .1) !default;

// Animations
$gb_transitionTiming: ease-in-out !default;
$gb_transitionFast: .15s !default;
$gb_transitionNormal: .3s !default;
$gb_transitionSlow: .5s !default;

// global
$gbBodyColor: $gb_grey_050 !default;

// z-index
$zLangSwitch: 1;
$zMinimap: 10;
$zBackdrop: 50;
$zSpinner: 55;
$zIntro: 60;
$zHeader: 99;
$zOverlay: 100;
