.gb-intro {
  position: relative;
  display: block;
  z-index: $zIntro;

  .gb-modal {
    transform: translate(0);
    transition: transform 1s ease-out;
  }

  &--hidden {
    .gb-modal {
      transform: translate(-100vw);
    }

    .gb-backdrop {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__content {
    button {
      margin-top: calc-pixel(15);

      i {
        display: none;
      }

      &:hover {
        padding-right: calc-pixel(30);
        padding-left: calc-pixel(30);
      }
    }
  }
}
