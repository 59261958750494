.gb-image-gallery {
  &--hidden {
    .gb-overlay {
      // opacity: 0;
      visibility: hidden;
      transform: translateY(100vh);
    }
  }

  &__scroll-content {
    height: 100%;
    overflow-y: scroll;
    padding-top: calc-pixel(80);
    padding-bottom: calc-pixel(80);
  }

  &__container {
    @include grid-container;
  }

  &__wrapper {
    padding-top: calc-pixel(40);
    padding-bottom: calc-pixel(80);
    margin-bottom: calc-pixel(50);
  }
}
