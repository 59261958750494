/* stylelint-disable */
@font-face {
  font-family: "geberitWeb20IconFont";
  src: url("#{$gb_iconPath}/geberitWeb20IconFont.eot?dc7682a31109141890920a0d9434f0c0?#iefix") format("embedded-opentype"),
url("#{$gb_iconPath}/geberitWeb20IconFont.woff?dc7682a31109141890920a0d9434f0c0") format("woff"),
url("#{$gb_iconPath}/geberitWeb20IconFont.ttf?dc7682a31109141890920a0d9434f0c0") format("truetype"),
url("#{$gb_iconPath}/geberitWeb20IconFont.svg?dc7682a31109141890920a0d9434f0c0#geberitWeb20IconFont") format("svg");
}

.web20-icon {
  line-height: 1;
}

[class*=web20-icon],
[class^=web20-icon],
.web20-icon {
  &::before {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    font-family: 'geberitWeb20IconFont' !important;
    font-style: normal;
    font-weight: normal !important;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
}

.web20-icon-arrow-link-light-right::before {
  content: '\f101';
}
.web20-icon-bullet::before {
  content: '\f102';
}
.web20-icon-chevron-large-left::before {
  content: '\f103';
}
.web20-icon-chevron-large-right::before {
  content: '\f104';
}
.web20-icon-chevron-small-left::before {
  content: '\f105';
}
.web20-icon-chevron-small-right::before {
  content: '\f106';
}
.web20-icon-close::before {
  content: '\f107';
}
.web20-icon-dash::before {
  content: '\f108';
}
.web20-icon-play::before {
  content: '\f109';
}
.web20-icon-plus::before {
  content: '\f10a';
}
.web20-icon-turn::before {
  content: '\f10b';
}
