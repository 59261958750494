.gb-overlay {
  position: absolute;
  background-color: $gb_white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zOverlay;
  transform: translateY(0);
  visibility: visible;
  // transition: visibility 0.3s, opacity 0.25s ease-out;
  transition: transform .3s ease-out, visibility .3s;

  &__wrapper {
    height: 100vh;
    position: relative;

    &__button {
      @include grid-container;
      position: relative;

      .close-button {
        position: absolute;
        right: 0;
        top: 0;
        width: calc-pixel(80);
        height: calc-pixel(80);
        background-color: $gb-white;
      }
    }

    &__content {
      height: 100%;
    }
  }

  &--black {
    background-color: $gb_black;

    .gb-overlay__wrapper__content {
      @include grid-container;
    }

    .close-button {
      background-color: transparent;

      i {
        color: $gb_white;
      }
    }
  }
}
