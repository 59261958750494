.gb-brand {
  position: relative;

  &__logo {
    display: block;
  }

  &__image {
    display: block;
    width: calc-pixel(146);
    height: auto;
  }

  &__claim {
    display: block;
    position: absolute;
    top: 0;
    left: calc-pixel(150);
    font-family: $gb_fontFamilyBase;
    font-weight: 400;
    font-size: calc-pixel(12);
    color: $gb_grey_400;
    line-height: 1;
    text-transform: uppercase;
  }
}
