.gb-lightbox {
  &--hidden {
    .gb-overlay {
      // opacity: 0;
      visibility: hidden;
      transform: translateY(100vh);
    }
  }

  &__content {
    height: 100%;
  }
}
