
.gb-minimap-gallery {
  &__container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .7);
    visibility: visible;
    overflow-y: auto;
    opacity: 1;
    transition: visibility .3s, opacity .25s ease-out;
    z-index: 50;
    @include tweakpoint-small {
      background-color: $gb-white;
      color: $gb-black;
    }
  }

  &__overlay {
    @include grid-container;
    position: absolute;
    top: $gb_headerHeightSm;
    left: 0;
    right: 0;
    bottom: 0;
    color: transparent;
    line-height: 0;
    font-size: 0;
    @include screen-md {
      top: $gb_headerHeightMd;
    }
  }

  &--hidden {
    background-color: rgba(0, 0, 0, 0);
    display: none;
  }

  &__overlay__button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    margin: calc-pixel(20) 0 calc-pixel(50) 0;
    cursor: pointer;
    @include tweakpoint-small {
      margin: calc-pixel(20) 0 0;
    }

    .close-button {
      cursor: pointer;

      i {
        color: $gb-white;
        @include tweakpoint-small {
          font-size: calc-pixel(16);
          color: $gb-black;
        }

        span {
          display: none;
        }
      }
    }
  }

  &__content {
    h3 {
      color: $gb-white;
      font-weight: $font-weight-bold;
      @include tweakpoint-small {
        color: $gb-black;
      }
    }

    &__items {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
    }

    &__item {
      margin-top: calc-pixel(30);
      width: calc-pixel(190);
      cursor: pointer;

      &:nth-of-type(odd) {
        @include tweakpoint-small {
          margin-right: calc-pixel(16);
        }
      }

      p {
        color: $gb-white;
      }
      @include tweakpoint-small {
        max-width: calc(50% - 8px);
        flex-basis: 50%;
        margin-top: calc-pixel(9);

        p {
          color: $gb-black;
        }

        img {

          /* padding: 24px & margin 8px per image */
          width: calc(50vw - 32px);

          /* keep image in 16:9 ratio */
          height: calc(((50vw - 32px) * (9 / 16)));
          object-fit: cover;
        }
      }
    }

    &__button-continue {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: calc-pixel(50);
      @include screen-md {
        .button {
          background: transparent;
          border-color: $gb_white;
          color: $gb_white;
          text-transform: none;

          &:hover {
            background: $gb_white;
            color: $gb_black;
            border-color: $gb_black;
            padding: calc-pixel(10) calc-pixel(30);
          }
        }
      }
      @include tweakpoint-small {
        padding: calc-pixel(24) 0;

        button {
          text-transform: none;
          width: 100%;
        }
      }
    }
  }
}
