.c-footer {
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding-bottom: calc-pixel(30);
  pointer-events: none;
  @include tweakpoint-small {
    padding-bottom: calc-pixel(16);
  }

  &__wrapper {
    @include grid-container;
    display: flex;
    flex-direction: row-reverse;
  }

  &__nav {
    @include tweakpoint-small {
      margin-right: calc-pixel(-10);
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
    }

    a,
    button {
      color: $gb_white;
      text-decoration: underline;
      margin-left: calc-pixel(30);
      font-size: calc-pixel(12);
      padding: 0;
      pointer-events: all;
      @include tweakpoint-small {
        margin-right: calc-pixel(10);
        margin-left: 0;
      }
    }

    button {
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:active,
      &:focus {
        outline: none;
      }
    }
  }
}
