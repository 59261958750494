.gb-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: $zBackdrop;
  width: 100vw;
  height: 100vh;
  visibility: visible;
  opacity: 1;
  transition: visibility .3s, opacity .25s ease-out;
  @include tweakpoint-small {
    overflow-y: scroll;
  }

  button {
    @include tweakpoint-small {
      width: 100%;
      margin: calc-pixel(24) 0;
    }
  }
}
