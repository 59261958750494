.gb-modal {
  background-color: $gb_white;
  position: relative;
  width: calc-pixel(440 - 60);
  min-height: calc-pixel(280 - 80);
  padding: calc-pixel(40) calc-pixel(30);
  margin: auto;
  @include screen-md {
    width: calc-pixel(470 - 60);
    min-height: calc-pixel(280 - 80);
  }

  &__wrapper {
    display: flex;
    height: 100vh;
  }
}
