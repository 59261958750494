@mixin screen-xl {
  @media (min-width: $gb_breakpointXL) {
    @content;
  }
}

@mixin screen-lg {
  @media (min-width: $gb_breakpointLG) {
    @content;
  }
}

@mixin screen-md {
  @media (min-width: $gb_breakpointMD) {
    @content;
  }
}

@mixin screen-sm {
  @media (min-width: $gb_breakpointSM) {
    @content;
  }
}

@mixin screen-xs {
  @media (min-width: $gb_breakpointXS) {
    @content;
  }
}

@mixin tweakpoint-small {
  @media (max-width: $gb_breakpointMD) {
    @content;
  }
}

@mixin set-responsive-font-sizes($xs, $sm, $md) {
  font-size: calc-pixel($xs);
  line-height: calc-line-height($xs);
  @include screen-sm {
    font-size: calc-pixel($sm);
    line-height: calc-line-height($sm);
  }
  @include screen-md {
    font-size: calc-pixel($md);
    line-height: calc-line-height($md);
  }
}

@mixin word-wrap() {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
}

@mixin grid-container() {
  padding-right: calc-pixel(24);
  padding-left: calc-pixel(24);
  max-width: calc-pixel(1164);
  margin-left: auto;
  margin-right: auto;
  @include screen-md {
    padding-right: calc-pixel(24);
    padding-left: calc-pixel(24);
  }
}
