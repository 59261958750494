.gb-tutorial {
  &--hidden {
    .gb-backdrop {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__content {
    @include grid-container;
    position: relative;
    margin: auto;
    color: $gb_white;
    text-align: center;
  }

  &__wrapper {
    display: flex;
    height: 100vh;
  }

  &__box-container {
    display: flex;
    margin-top: calc-pixel(64);
    margin-bottom: calc-pixel(48);
    margin-left: calc-pixel(-15);
    flex-direction: row;
    @include screen-md() {
      margin-left: 0;
    }
    @include tweakpoint-small {
      flex-direction: column;
      justify-content: center;
      margin: calc-pixel(64) 0 0;
    }
  }
}

.gb-box {
  .info-img- {
    &0 {
      width: calc-pixel(42);
    }

    &1 {
      width: calc-pixel(53);
    }

    &2 {
      margin-top: calc-pixel(-20);
      width: calc-pixel(82);
    }
  }

  div:first-of-type {
    height: calc-pixel(70);
  }

  div:nth-of-type(2) {
    height: calc-pixel(90);
  }

  p {
    padding-top: calc-pixel(10);
    height: calc-pixel(80);
    color: $gb_white;
    @include tweakpoint-small {
      height: calc-pixel(48);
    }
  }
}
