.gb-mobile-menu {
  display: none;
  @include tweakpoint-small {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: calc-pixel(24);
    bottom: calc-pixel(72);

    button {
      margin-top: calc-pixel(8);
    }
  }
}
