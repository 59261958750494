body {
  background: $gb_grey_050;
}

.gb-container {
  padding-right: .625rem;
  padding-left: .625rem;
  max-width: 76.875rem;
  margin-left: auto;
  margin-right: auto;
  @include screen-md {
    padding-right: .9375rem;
    padding-left: .9375rem;
  }
}
