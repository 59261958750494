.gb-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: $gb_headerHeightSm;
  min-height: calc-pixel(60);
  font-family: $gb_fontFamilyBase;
  line-height: $gb_lineHeightBase;
  z-index: $zHeader;
  background: $gb_white;
  border-bottom: .0625rem solid $gb_grey_075;
  box-shadow: $gb_boxShadowDefault;
  @include screen-md {
    height: $gb_headerHeightMd;
  }

  &__container {
    @include grid-container;
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  &__brand {
    width: calc-pixel(200);
    @include screen-md {
      width: calc-pixel(220);
      padding-top: calc-pixel(5);
    }
  }

  &__language-switch {
    align-self: baseline;
    flex-grow: 4;
    padding: 0;
    margin: calc-pixel(30) 0 0;
    font-family: $gb_fontFamilyBase;
    line-height: $gb_lineHeightBase;
    @include tweakpoint-small {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-self: center;
      margin: 0;
      position: relative;
    }

    li {
      list-style: none;
      margin: 0 0 10px;
      padding: 0 45px;

      a {
        color: $gb_grey_650;

        &:hover {
          text-decoration: none;
          color: $gb_brandPrimaryMedium;
        }
      }

      strong {
        font-weight: normal;
        color: $gb_brandPrimaryMedium;
      }
    }

    ul {
      display: none;
      list-style: none;
      position: relative;
      margin: 0;
      padding: .85rem 0;
      background-color: $gb_white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
      top: 10px;
      left: -20px;

      &::before {
        border-width: 0 .625rem .625rem;
        border-color: transparent transparent $gb_white;
        z-index: $zLangSwitch;
        position: absolute;
        top: -.55rem;
        left: 1.625rem;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        @include tweakpoint-small {
          left: unset;
          right: 0;
        }
      }

      &::after {
        content: '';
        border-width: 0 .75rem .675rem;
        border-color: transparent transparent $gb_grey_100;
        position: absolute;
        top: -.675rem;
        left: 1.625rem;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        @include tweakpoint-small {
          left: unset;
          right: calc-pixel(-4);
        }
      }
      @include tweakpoint-small {
        position: absolute;
        top: calc-pixel(32);
        right: 0;
      }
    }

    .selected-language-abbr {
      font-weight: bold;
      background-image: url('../images/web/chevron-large-down.svg');
      background-repeat: no-repeat;
      background-position: center right;
      background-size: 14px 9px;
      padding-right: 15px;
    }

    div {
      display: none;
      padding: 0 0 10px 20px;

      &.show {
        display: inline-block;
        @include tweakpoint-small {
          padding: 0;
        }
      }

      &:hover {
        ul {
          display: block;
        }

        .selected-language-abbr {
          color: $gb_brandPrimaryMedium;
          cursor: pointer;
          background-image: url('../images/web/chevron-large-up.svg');
        }
      }
    }

    .selected-language {
      background-image: url('../images/web/check.svg');
      background-repeat: no-repeat;
      background-position: 18px center;
      background-size: 22px 22px;
    }
  }

  &__nav {
    display: none;
    @include screen-sm {
      display: block;
    }

    a {
      i {
        display: none;
      }

      &:hover {
        padding-right: calc-pixel(30);
        padding-left: calc-pixel(30);
      }
    }
  }

  &__nav,
  &__brand {
    align-self: center;
  }
}
