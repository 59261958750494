.loading-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: visibility .3s ease-out;

  .gb-backdrop {
    opacity: 1;
    visibility: visible;
  }

  .c-loader {
    z-index: $zSpinner;
    opacity: 1;
    visibility: visible;
    transition: visibility .3s, opacity .25s ease-out;
    background-color: transparent;

    &--small {
      width: 2rem;
      height: 2rem;
    }

    &--medium {
      width: 4rem;
      height: 4rem;
    }

    &--large {
      width: 8rem;
      height: 8rem;
    }

    span {
      display: inline-block;
      background: transparent;
      width: 25%;
      height: 25%;
      margin: 3%;
      animation: loading-fade-black 1s linear infinite;

      &:nth-child(5) {
        background: transparent;
        animation: none;
      }

      &:nth-child(1) {
        animation-delay: -.875s;
      }

      &:nth-child(2) {
        animation-delay: -.75s;
      }

      &:nth-child(3) {
        animation-delay: -.625s;
      }

      &:nth-child(6) {
        animation-delay: -.5s;
      }

      &:nth-child(9) {
        animation-delay: -.375s;
      }

      &:nth-child(8) {
        animation-delay: -.25s;
      }

      &:nth-child(7) {
        animation-delay: -.125s;
      }
      @keyframes loading-fade-black {
        0% {
          background: rgba($gb-black, .5);
        }

        12.5% {
          background: rgba($gb-black, .5);
        }

        20% {
          background: transparent;
        }

        100% {
          background: transparent;
        }
      }
      @keyframes loading-fade-white {
        0% {
          background: rgba($gb-white, .5);
        }

        12.5% {
          background: rgba($gb-white, .5);
        }

        20% {
          background: transparent;
        }

        100% {
          background: transparent;
        }
      }
      @keyframes loading-fade-brand {
        0% {
          background: rgba($gb_brandPrimary, .5);
        }

        12.5% {
          background: rgba($gb_brandPrimary, .5);
        }

        20% {
          background: transparent;
        }

        100% {
          background: transparent;
        }
      }
    }

    &--color-white {
      span {
        animation: loading-fade-white 1s linear infinite;
      }
    }

    &--color-brand {
      span {
        animation: loading-fade-brand 1s linear infinite;
      }
    }
  }
}

.loading-indicator--hidden {
  visibility: hidden;

  .gb-backdrop {
    opacity: 0;
    visibility: hidden;
  }

  .c-loader {
    opacity: 0;
    visibility: hidden;
  }
}
