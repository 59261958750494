// Global typo
body {
  color: $gb_black;
  font-family: $gb_fontFamilyBase;
  font-size: $gb_fontSizeBase;
  line-height: $gb_lineHeightBase;
}

// Headlines
h1,
.h1 {
  margin: 0 0 10px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: calc-pixel(.68);
  @include set-responsive-font-sizes(32, 46, 46);
  @include word-wrap();

  & + .h2,
  + h2 {
    margin-top: calc-pixel(-10);
  }

  b {
    display: block;
    font-weight: 700;
  }
}

h2,
.h2 {
  margin: 0 0 15px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: calc-pixel(.68);
  @include set-responsive-font-sizes(28, 34, 36);
  @include word-wrap();

  b {
    display: block;
    font-weight: 700;
  }
}

h3,
.h3 {
  margin: 0 0 15px;
  font-weight: 300;
  @include set-responsive-font-sizes(18, 26, 30);

  b {
    display: block;
    font-weight: 700;
  }
}

h4,
.h4 {
  margin: 0 0 15px;
  font-weight: 700;
  @include set-responsive-font-sizes(15, 18, 22);
}

h5,
.h5 {
  margin: 0 0 10px;
  font-weight: 700;
  @include set-responsive-font-sizes(14, 14, 16);
}

h6,
.h6 {
  margin: 0 0 10px;
  font-weight: 700;
  font-size: calc-pixel(14);
}

// Text
p {
  font-size: $gb_fontSizeBase;
  line-height: $gb_lineHeightBase;
  color: $gb_black;
  margin-bottom: calc-pixel(15);
}

b,
strong {
  font-weight: 700;
}

label {
  font-weight: 400;
}

// Lists
ol,
ul {
  margin: 0 0 calc-pixel(15);
  padding: 0 0 0 calc-pixel(17);
}

ul {
  list-style: disc;
  line-height: calc-pixel(24);

  li {
    padding-bottom: .625rem;
  }
}

ol {
  list-style: decimal;
}

.gb-list__link {
  padding: 0;
  list-style: none;
}
