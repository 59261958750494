.gb-minimap {
  background-color: rgba(0, 0, 0, .75);
  bottom: calc-pixel(35);
  min-height: calc-pixel(100);
  left: calc-pixel(50);
  padding: calc-pixel(12) calc-pixel(48) calc-pixel(12) calc-pixel(32);
  position: absolute;
  min-width: calc-pixel(200);
  color: transparent;
  line-height: 0;
  font-size: 0;
  z-index: $zMinimap;
  display: flex;
  flex-direction: column;
  @include tweakpoint-small {
    background-color: #000;
    right: 0;
    left: 0;
    bottom: 0;
    padding: calc-pixel(56) calc-pixel(46) calc-pixel(56) calc-pixel(46);
  }

  &--hidden {
    display: none;
  }

  &-map {
    position: relative;
    display: inline-block;
    align-self: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  &-dot {
    background: url('../../assets/images/minimap/marker-minimap.svg');
    position: absolute;
    width: calc-pixel(15);
    height: calc-pixel(15);
    cursor: pointer;

    &--current {
      background: url('../../assets/images/minimap/marker-active.svg');
    }
  }

  .close-button {
    display: none;
    @include tweakpoint-small {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      margin-right: calc-pixel(24);
      margin-top: calc-pixel(24);

      i {
        font-size: calc-pixel(16);
        color: $gb-white;
      }
    }
  }

  .button-open-grid {
    &:focus {
      outline: none;
    }

    span {
      display: none;
    }
  }

  .gb-minimap-light-beam {
    background: url('/src/assets/images/minimap/light-beam.svg');
    background-repeat: no-repeat;
    width: calc-pixel(56);
    height: calc-pixel(49);
    position: absolute;
    right: 0;
    bottom: 0;
    padding: calc-pixel(3);
  }
}
